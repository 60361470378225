import React from 'react'
import MetaData from '../components/MetaData'
import { Container, Row, Col } from 'react-bootstrap'
import PageWrapper from '../components/PageWrapper'
import { Section, Title, Text } from '../components/Core'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const Privacy = () => {
  const { t, ready } = useTranslation(['privacy'], { useSuspense: false })
  const userSettings = useSelector(state => state.user.user_settings)
  const userSessionExpired = useSelector(state => state.user.session_expired)
  const userDarkModeSelector = useSelector(state => state.user.darkMode)
  const userDarkMode = userSessionExpired
    ? userDarkModeSelector
    : typeof userSettings !== 'undefined'
    ? userSettings.dark_mode
    : false

  return ready && (
    <>
      <MetaData page="default" />
      <PageWrapper footerDark>
        <Section bg={userDarkMode ? 'black' : 'light'}>
          <div className="pt-5"></div>
          <Container>
            <Row className="justify-content-center">
              <Col lg="9" xl="8">
                <Title color={userDarkMode ? '#FAFAFA' : 'black'} className="mt-5" variant="hero" color={userDarkMode ? 'light' : 'dark'}>
                  {t('heroTitle')}
                </Title>

                <Title color={userDarkMode ? '#FAFAFA' : 'black'} className="mt-5" variant="card">
                  {t('overview')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('committed')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('personalInfo')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('controller')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('headquarters')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('byUsing')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('anyQuestions')}
                </Text>

                <Title color={userDarkMode ? '#FAFAFA' : 'black'} className="mt-5" variant="card">
                  {t('howWeUse')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('weUseYour')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('shouldYouWish')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('theSituation')}
                </Text>

                <Title color={userDarkMode ? '#FAFAFA' : 'black'} className="mt-5" variant="card">
                  {t('youProvide')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('thisInfo')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('youMay')}
                </Text>

                <Title color={userDarkMode ? '#FAFAFA' : 'black'} className="mt-5" variant="card">
                  {t('usingOur')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('ourProvision')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('weMayUse')}
                </Text>

                

                <Title color={userDarkMode ? '#FAFAFA' : 'black'} className="mt-5" variant="card">
                  {t('events')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('lsEvents')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('weMayShare')}
                </Text>

                <Title color={userDarkMode ? '#FAFAFA' : 'black'} className="mt-5" variant="card">
                  {t('siteUseInfo')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('ourWebServers')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('followingPurposes')}
                </Text>
                <ul className="ml-5">
                  <li type="circle">
                    <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                      {t('toMeasure')}
                    </Text>
                  </li>
                  <li type="circle">
                    <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                    {t('toAdminister')}
                    </Text>
                  </li>
                  <li type="circle">
                    <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                    {t('asPart')}
                    </Text>
                  </li>
                </ul>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('thirdParties')}
                </Text>

                <Title color={userDarkMode ? '#FAFAFA' : 'black'} className="mt-5" variant="card">
                  {t('cookiePolicy')}
                </Title>
                <Title color={userDarkMode ? '#FAFAFA' : 'black'} className="mt-5" variant="card">
                  {t('overview')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('likeMost')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('whenYou')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('youDoNot')}
                </Text>

                <Title color={userDarkMode ? '#FAFAFA' : 'black'} className="mt-5" variant="card">
                  {t('whatAreCookies')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('cookies')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('weUseCookies')}
                </Text>

                <Title color={userDarkMode ? '#FAFAFA' : 'black'} className="mt-5" variant="card">
                  {t('howToControl')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('whenYouFirst')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('consentCan')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('pleaseFollow')}
                </Text>
                <ul className="ml-5">
                  <li type="circle">
                    <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                      <a target="_blank" href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=en">
                        Google Chrome
                      </a>
                    </Text>
                  </li>
                  <li type="circle">
                    <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                      <a target="_blank" href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies">
                        Microsoft Internet Explorer
                      </a>
                    </Text>
                  </li>
                  <li type="circle">
                    <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                      <a target="_blank" href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">
                        Mozilla Firefox
                      </a>
                    </Text>
                  </li>
                  <li type="circle">
                    <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                    <a target="_blank" href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac">
                    Apple Safari
                    </a>
                    </Text>
                  </li>
                </ul>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('inAddition')}
                </Text>
                <ul className="ml-5">
                  <li type="circle">
                    <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                      <a target="_blank" href="http://www.aboutads.info/choices/">
                        http://www.aboutads.info/choices/
                      </a>
                    </Text>
                  </li>
                  <li type="circle">
                    <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                      <a target="_blank" href="http://www.networkadvertising.org/choices/">
                        http://www.networkadvertising.org/choices/
                      </a>
                    </Text>
                  </li>
                  <li type="circle">
                    <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                      <a target="_blank" href="http://youronlinechoices.com/">
                        http://youronlinechoices.com/
                      </a>
                    </Text>
                  </li>
                </ul>

                <Title color={userDarkMode ? '#FAFAFA' : 'black'} className="mt-5" variant="card">
                  {t('webBeacons')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('asWell')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('allowUs')}
                </Text>
                

                <Title color={userDarkMode ? '#FAFAFA' : 'black'} className="mt-5" variant="card">
                  {t('changesToThis')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('amend')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('provisionsContained')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('pleaseCheck')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('encourage')}
                </Text>

                <Title color={userDarkMode ? '#FAFAFA' : 'black'} className="mt-5" variant="card">
                  {t('contact')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('support')}
                </Text>

                <Title color={userDarkMode ? '#FAFAFA' : 'black'} className="mt-5" variant="card">
                  {t('doNotTrack')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('recognizes')}
                </Text>

                <Title color={userDarkMode ? '#FAFAFA' : 'black'} className="mt-5" variant="card">
                  {t('optingOut')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('ifYouHave')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('evenIf')}
                </Text>

                <Title color={userDarkMode ? '#FAFAFA' : 'black'} className="mt-5" variant="card">
                  {t('disclosing')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('weWillNot')}
                </Text>
                <ul className="ml-5">
                  <li type="circle">
                    <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                      {t('primeTrust')}
                    </Text>
                  </li>
                  <li type="circle">
                    <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                    {t('youRequest')}
                    </Text>
                  </li>
                  <li type="circle">
                    <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                    {t('weWillDisclose')}
                    </Text>
                  </li>
                  <li type="circle">
                    <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                      {t('whereYou')}
                    </Text>
                  </li>
                  <li type="circle">
                    <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                    {t('partOf')}
                    </Text>
                  </li>
                  <li type="circle">
                    <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                    {t('toComplyWith')}
                    </Text>
                  </li>
                </ul>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('weWillAlso')}
                </Text>

                <Title color={userDarkMode ? '#FAFAFA' : 'black'} className="mt-5" variant="card">
                  {t('security')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('encryptInfo')}
                </Text>

                <Title color={userDarkMode ? '#FAFAFA' : 'black'} className="mt-5" variant="card">
                  {t('retention')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('weWillStore')}
                </Text>

                <Title color={userDarkMode ? '#FAFAFA' : 'black'} className="mt-5" variant="card">
                  {t('linksTo')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('ourSites')}
                </Text>

                <Title color={userDarkMode ? '#FAFAFA' : 'black'} className="mt-5" variant="card">
                  {t('child')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('underAge')}
                </Text>

                <Title color={userDarkMode ? '#FAFAFA' : 'black'} className="mt-5" variant="card">
                  {t('outside')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('ifYouAccess')}
                </Text>

                <Title color={userDarkMode ? '#FAFAFA' : 'black'} className="mt-5" variant="card">
                  {t('california')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('forCaliResidents')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('toYourEmail')}
                </Text>

                <Title color={userDarkMode ? '#FAFAFA' : 'black'} className="mt-5" variant="card">
                  {t('european')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('forEUResidents')}
                </Text>
                <ul className="ml-5">
                  <li type="circle">
                    <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                      {t('requestAccess')}
                    </Text>
                  </li>
                  <li type="circle">
                    <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                    {t('obtainWithout')}
                    </Text>
                  </li>
                  <li type="circle">
                    <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                    {t('requestThat')}
                    </Text>
                  </li>
                  <li type="circle">
                    <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                      {t('restrict')}
                    </Text>
                  </li>
                  <li type="circle">
                    <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                    {t('requestTransfer')}
                    </Text>
                  </li>
                </ul>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('whereYouBelieve')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('exercise')}
                </Text>

                <Title color={userDarkMode ? '#FAFAFA' : 'black'} className="mt-5" variant="card">
                  {t('governingLaw')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('byChoosing')}
                </Text>

                <Title color={userDarkMode ? '#FAFAFA' : 'black'} className="mt-5" variant="card">
                  {t('changesTo')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('weReserve')}
                </Text>

                <Title color={userDarkMode ? '#FAFAFA' : 'black'} className="mt-5" variant="card">
                  {t('contact')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('ifYou')}
                </Text>
    
              </Col>
            </Row>
          </Container>
        </Section>
      </PageWrapper>
    </>
  )
}
export default Privacy
